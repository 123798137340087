#root, body, html{
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  background-color: rgb(41, 41, 41);
  

}
body {
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
::-webkit-scrollbar {
  width: 10px;
  
}
::-webkit-scrollbar-track{
  background:rgba(0, 0, 0, 0);
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(90deg, rgb(0, 140, 255) 0%, rgb(0, 102, 255) 100%);
  
  border:3px rgb(41, 41, 41) solid;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background:rgb(0, 140, 255);
}
::-webkit-scrollbar-corner{
    display:none;
}

