
@media screen and (max-height:880px) {

    .ReplayDock{
      margin-top: 0px!important;
      -webkit-transform: translate(0,-50%)!important;
      -ms-transform: translate(0,-50%)!important;
          transform: translate(0,-50%) !important;
    }
  
}

@media screen and (max-width: 700px) {
.ProfileDiv{
  -webkit-transform: translate(-50%,-20%) scale(.8)!important;
  -ms-transform: translate(-50%,-12%)scale(.8)!important;
      transform: translate(-50%,-12%) scale(.8)!important;
}
}

@media screen and (max-width: 464px) {
  .ProfileDiv{
  
    -webkit-transform: translate(-50%,-12%) scale(.65)!important;
    -ms-transform: translate(-50%,-12%)scale(.65)!important;
        transform: translate(-50%,-12%) scale(.65)!important;
  }
  }


@media screen and (max-width: 420px) {

  .HomeDiv{

    -webkit-transform: translate(-50%) scale(.9)!important;

        -ms-transform: translate(-50%) scale(.9)!important;

            transform: translate(-50%) scale(.9)!important;

    
  }
  .Lobby.actual{
    -webkit-transform: scale(.95);
        -ms-transform: scale(.95);
            transform: scale(.95);
  }




}

@media screen and (max-width: 380px) {

  .HomeDiv{

    -webkit-transform: translate(-50%) scale(.85)!important;

        -ms-transform: translate(-50%) scale(.85)!important;

            transform: translate(-50%) scale(.85)!important;

    
  }
  .Lobby.actual{
    -webkit-transform: scale(.85);
        -ms-transform: scale(.85);
            transform: scale(.85);
  }

}

@media screen and (max-width: 920px), (max-height:885px) {


  .GameReplayContainer>.GameDiv{
    -webkit-transform: translate(0px) scale(.8)!important;
        -ms-transform: translate(0px) scale(.8)!important;
            transform: translate(0px) scale(.8)!important;
  }


  .GameDiv{

      -webkit-transform: translate(-50%) scale(.8)!important;

          -ms-transform: translate(-50%) scale(.8)!important;

              transform: translate(-50%) scale(.8)!important;


  }
  
  .GameBoard>.Grid>.GridRow>.GridSpace{
    border-color: rgb(63, 63, 63)!important;
    border-width: 1px!important;
    
    
  }

}

@media screen and (max-width: 525px){

  .GameReplayContainer>.GameDiv{
    -webkit-transform: translate(0px) scale(.55)!important;
        -ms-transform: translate(0px) scale(.55)!important;
            transform: translate(0px) scale(.55)!important;
  }

  .GameDiv{

    -webkit-transform: translate(-50%) scale(.55)!important;

        -ms-transform: translate(-50%) scale(.55)!important;

            transform: translate(-50%) scale(.55)!important;


}

}

@media screen and (max-width: 450px) and (max-height:700px){

  .GameReplayContainer>.GameDiv{
    -webkit-transform: translate(0px) scale(.55)!important;
        -ms-transform: translate(0px) scale(.55)!important;
            transform: translate(0px) scale(.55)!important;
  }

  .GameDiv{

    -webkit-transform: translate(-50%,-15%) scale(.55)!important;

        -ms-transform: translate(-50%,-15%) scale(.55)!important;

            transform: translate(-50%,-15%) scale(.55)!important;


}

}



@media screen and (max-width: 900px) {


  .HomeDiv{
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: reverse!important;
        -ms-flex-direction: column-reverse!important;
            flex-direction: column-reverse!important;
    min-width: none !important;
    width: -webkit-fit-content!important;
    width: -moz-fit-content!important;
    width: fit-content!important;
    left: 50%;
    -webkit-transform: translate(-50%);
        -ms-transform: translate(-50%);
            transform: translate(-50%);
    top: 50px!important;
    height: -webkit-fit-content!important;
    height: -moz-fit-content!important;
    height: fit-content!important;
    
  }
  .HomeDiv>.HomeRight{
    margin-bottom: 10px!important;

  }
  .HomeDiv>div{
    margin: 0px !important;
  }
  .CreateLobbyDiv{
    margin-top: 10px!important;
  }

}

.App {
  text-align: center;
  height: 100%;
  background-color: rgb(41, 41, 41);

}

.PlayingPiece{


  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 10px;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
 
}

.PlayingPiece.selected{
  opacity: .8;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);


}

.placedpiece.used{
  opacity: .5 !important;
  -webkit-transform:scale(1) ;
  -ms-transform:scale(1) ;
  transform: scale(1);
  pointer-events: none!important;

  
}



.HandContainer{
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 130px;
  background-color: rgba(0, 0, 0, 0.3);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 50px;
  border-radius: 10px;
  
  

}

.GameBoard{
  position: relative;
  margin-top: 50px;

}

.Hand{

  width: 100%;

  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;


  display: -webkit-box;


  display: -ms-flexbox;


  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;

  -webkit-box-align: center;

      -ms-flex-align: center;

          align-items: center;


  position: relative;
  
  



}
.Hand>.pieces{

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  height: 100px;
  
  position: absolute;

  left: 0px;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  opacity: 1;

  -webkit-box-align: center;

      -ms-flex-align: center;

          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  overflow-y: scroll;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  bottom: 0px;



}
.Hand>.pieces>div{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  height: 100px;
  
  position: absolute;

  left: 0px;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  opacity: 1;

  -webkit-box-align: center;

      -ms-flex-align: center;

          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  overflow-y: scroll;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  bottom: 0px;
  display: none;
}
.Hand>.pieces>.shown{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.pieces::-webkit-scrollbar{
  display: none;
}

.Hand>.pieces>div::-webkit-scrollbar{
  display: none;
}

.Hand>.pieces>div>.handpiece>.PlayingPiece>.blocks>.row>*{
    width: 10px;
    height: 10px;
}
.Hand>.pieces>div>.handpiece>.PlayingPiece>.blocks>.row>*>*{
  width: 5px;
  height: 5px;
}
.Hand>.pieces>div>.placedpiece>.PlayingPiece>.row>*{
  width: 10px!important;
  height: 10px!important;
}
.Hand>.pieces>div>.placedpiece>.PlayingPiece>.row>*>*{
width: 5px!important;
height: 5px!important;
}




.Hand>.pieces>div>.placedpiece>.PlayingPiece.selected{
  position: relative;
  opacity: 1;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  
  

}

.Hand>.pieces>div>.placedpiece{
  
  border-radius: 5px;
  
  min-width: 40px;
  height: 40px;

  padding-left: 5px;
  padding-right: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 2px;
  
  cursor: pointer;

  -webkit-transition: all .25s ease-in;

  -o-transition: all .25s ease-in;

  transition: all .25s ease-in;

}
.Hand>.pieces>.ownhand>.placedpiece{
  pointer-events: all;
}
.Hand>.pieces>div>.placedpiece{
  pointer-events: none;
}

.ownhand>.placedpiece:hover{
  background-color: rgba(0, 0, 0, 0.3);
  
  
}

.ownhand>.placedpiece.selected{
  background-color: rgba(0, 0, 0, 0.4);
}

.pieces>div>.placedpiece>.PlayingPiece{
  margin: 0px;
}

.row{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin-top: -2px;
  
}



.Block{
  background-color: white;
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-color: rgba(0, 0, 0, 0.151);
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  margin-left: -2px;
  opacity: .85;


 
}



.Block>div{
  background-color: black;
  opacity: .15;
  width: 18px;
  height: 18px;
  border-color: rgb(255, 255, 255);
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
}
.EmptySpace{
  background-color: rgba(0, 0, 255, 0);
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-color: rgba(0, 0, 0, 0);
  border-width: 1px;
  border-style: solid;
  margin-left: -2px;
}

.Grid{
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;

  border-color: rgb(0, 0, 0);
  border-width: 5px;
  border-style: solid;
  background-color: rgb(0, 0, 0);
  border-radius: 5px;
  z-index: 1000;
}
.GridRow{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;

}
.GridSpace{
  background-color: rgb(80, 80, 80);
  width: 32px;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-color: rgba(0, 0, 0, 0.151);
  border-width: 1px;
  border-style: solid;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 2px;
}
.GameDiv{


  background-repeat: repeat;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
left: 50%;
  -webkit-transform:translate(-50%) ;
  -ms-transform: translate(-50%);
transform: translate(-50%);
}

.SelectionControl{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  left: 50%;
  top: 100%;
  -webkit-transform: translate(-50%,5px);
  -ms-transform: translate(-50%,5px);
  transform: translate(-50%,5px);
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;

}



.SelectionControl.show{
  opacity: 1;
  pointer-events: all;
}


.SelectionControl>button{
  cursor: pointer;
  background-color: rgb(65, 65, 65);
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  border-width: 0px;


}
.SelectionControl>button:hover{
  
  opacity: .9;
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  
}
.SelectionControl>.flip{
  border-style: solid;
  width: 80px;
  height: 50px;
  border-radius: 100px 50px 50px 100px;

  background-image: url("./Icons/flip.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  margin-right: 10px;


}



.SelectionControl>.close{
  border-style: solid;
  width: 120px;
  height: 120px;
  border-radius: 100%;
  background-color: rgb(104, 0, 0);
  
  background-image: url("./Icons/close.png");
  background-size: 50px;
  background-repeat: no-repeat;
  background-position: 50%;
}
.SelectionControl>.rotate{
  border-style: solid;
  width: 80px;
  height: 50px;
  border-radius: 50px 100px 100px 50px;
  background-image: url("./Icons/rotate.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  
  
}

.Grid>div>.PlayingPiece{
  opacity: 1!important;
  -webkit-transform:scale(1) translate(-10px, -10px)!important ;
  -ms-transform: scale(1) translate(-10px, -10px)!important;
  transform: scale(1) translate(-10px, -10px)!important;
  position: absolute;
  bottom: 0px;
  left: 0px;

}
.Grid>div>.PlayingPiece>.row>*{
  margin-left: 0px!important;
  

}
.Grid>div>.PlayingPiece>.row{
  margin-top: 0px!important;
}

.Grid>div>.PlayingPiece>.blocks>.row>*{
  margin-left: 0px!important;
  

}
.Grid>div>.PlayingPiece>.blocks>.row{
  margin-top: 0px!important;

}
.Grid>div>.PlayingPiece>.blocks{
  pointer-events: none;
}


.PlayingPiece.onboard{

  -webkit-transition: initial !important;

  -o-transition: initial !important;

  transition: initial !important;


}

.PlayingPiece.onboard>.row{
  pointer-events: none;

}

.piecemenu{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  -webkit-transform: translate(-50%, 100%);
  -ms-transform: translate(-50%, 100%);
  transform: translate(-50%, 100%);
  left: 50%;
  bottom: -5px;


  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;


}

.piecemenu>.orientation{
  background-color: rgba(0, 0 , 0, .40);
  width: 105px;
  height: 34px;
  position: relative;
  border-radius: 100px 100px 100px 100px;
  margin-bottom: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;


}

.orientation>button{
  width: 34px;
  height: 34px;
  border-radius: 100%;
  background-color: rgba(0, 0 , 0, 0);
  border-style: none;

  background-repeat: no-repeat;
  background-size: 17px 17px;
  background-position: 50% 50%;
  cursor: pointer;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;

}

.orientation>.pmflip{
  background-image: url("./Icons/flip.png");
left: 0px;
position: absolute;
}

.orientation>.pmrotate{
  background-image: url("./Icons/rotate.png");
  right: 0px;
position: absolute;

}

.orientation>button:hover{
  background-color: rgba(0, 0 , 0, .2);
}
.orientation>button.clicked{
  -webkit-transform: scale(.8);
  -ms-transform: scale(.8);
  transform: scale(.8);
}
.orientation>.dragbar{
  height: 34px;
  width: 30%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: -webkit-grabbing;
  cursor: grabbing;
  -ms-touch-action: none;
      touch-action: none;
  

  
}
button:focus{
  outline: none;
}
.GameDiv:focus{
  outline: none;
}
.orientation>.dragbar:hover>img{
 opacity: 1;
}

.orientation>.dragbar>img{
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  opacity: .8;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  pointer-events: none;

  -webkit-transition: opacity .25s ease-in;

  -o-transition: opacity .25s ease-in;

  transition: opacity .25s ease-in;
}
.piecemenu>.pieceselection{
  height: 16px;
  width: 46px;
  background-color: rgba(0, 0 , 0, .4);
  position: relative;
  border-radius: 100px 100px 100px 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;

}

.pieceselection>button{
  background-color: rgba(0, 0, 0, 0);
  border-style: none;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: absolute;
  background-size: 12px 12px;
  background-image: url("./Icons/arrow.png");
background-repeat: no-repeat;
background-position: 50% 50%;
-webkit-transition: all .25s ease-in;
-o-transition: all .25s ease-in;
transition: all .25s ease-in;

}

.pieceselection>button:hover{
  background-color: rgba(0, 0 , 0, .2);
}

.pieceselection>.prev{
  top: 0px;
left: 0px;

}
.pieceselection>.next{
  top: 0px;
right: 0px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
transform: rotate(180deg);
}
.pieceselection>label{
  background-color: rgba(0, 0, 0, 0);
  color: white;
  line-height: 16px;
  font-family: 'Josefin Sans', sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  font-weight: 400;
  -webkit-transform: translateY(2px);
      -ms-transform: translateY(2px);
          transform: translateY(2px);
}

.placepiecebtn{

  color: rgb(0, 141, 0);
  width: 20px;
  height: 20px;
  position: absolute;
  cursor: pointer;
  left: 100%;
  top: 17px;
  -webkit-transform:translate(5px,-50%) ;
  -ms-transform:translate(5px,-50%) ;
  transform: translate(5px,-50%);
  -webkit-transition: opacity .25s ease-in;
  -o-transition: opacity .25s ease-in;
  transition: opacity .25s ease-in;
  

}
.placepiecebtn:hover{
  opacity: .8;
}

.peekbar{
  height: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  top: 0px;
  left: 0px;
  position: absolute;
  -webkit-transform:translate(5px,5px) ;
  -ms-transform:translate(5px,5px) ;
  transform: translate(5px,5px);


}
.peekbar>.colorpeekbtn{
  background-color: rgba(0, 0, 0, 0.35);
  width: 24px;
  height: 18px;
  border-radius: 5px;
  margin-right: 5px;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
}

.peekbar>.colorpeekbtn:hover{
  background-color: rgba(0, 0, 0, 0.4);
}
.colorpeekbtn>div{
  position: relative;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform:translate(-50%,-50%) ;
  transform: translate(-50%,-50%);
  pointer-events: none;

}

.peekbar>.hover{
  position: absolute;
  border-radius: 100%;
  border-width: 1px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-color: rgba(255, 255, 255, 0.4);
  background-color: rgba(0, 0, 0, 0.233);
  border-style: solid;
  width: 10px;
  height: 10px;
  opacity: 1;
  left: 0px;
  -webkit-transform: translate(7px,4px);
  -ms-transform: translate(7px,4px);
  transform: translate(7px,4px);
  pointer-events: none;
  -webkit-transition: left .25s ease-out;
  -o-transition: left .25s ease-out;
  transition: left .25s ease-out;
}
.placedpiece{
  pointer-events: none;
}

.PlayingPiece{
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
}



.Grid.error>.boardpiece>.onboard>.blocks{
  -webkit-animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
          animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;



}
.blocks{
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
}

.Grid.loading>.boardpiece{
  pointer-events: none;
}
.Grid.loading>.boardpiece>.onboard>.blocks{
  -webkit-animation: pulse 1s infinite ease-in-out;
          animation: pulse 1s infinite ease-in-out;
}

.Grid>.placedpiece>.PlayingPiece{
  -webkit-transition: initial !important;
  -o-transition: initial !important;
  transition: initial !important;
}
@-webkit-keyframes pulse {
  0% { opacity: 0.7; }
  50% { opacity: 1; }
  100% {  opacity: 0.7; }
}
@keyframes pulse {
  0% { opacity: 0.7; }
  50% { opacity: 1; }
  100% {  opacity: 0.7; }
}

@-webkit-keyframes shake {
  10%, 90% {
  -webkit-transform: translate(-1px, 0);
  -ms-transform: translate(-1px, 0);
    transform: translate(-1px, 0);
  }
  
  20%, 80% {
  -webkit-transform: translate(2px, 0);
  -ms-transform:translate(2px, 0) ;
    transform: translate(2px, 0);
  }

  30%, 50%, 70% {
  -webkit-transform: translate(-4px, 0);
  -ms-transform: translate(-4px, 0);
    transform: translate(-4px, 0);
  }

  40%, 60% {
  -webkit-transform: translate(4px, 0);
  -ms-transform: translate(4px, 0);
    transform: translate(4px, 0);
  }
}

@keyframes shake {
  10%, 90% {
  -webkit-transform: translate(-1px, 0);
  -ms-transform: translate(-1px, 0);
    transform: translate(-1px, 0);
  }
  
  20%, 80% {
  -webkit-transform: translate(2px, 0);
  -ms-transform:translate(2px, 0) ;
    transform: translate(2px, 0);
  }

  30%, 50%, 70% {
  -webkit-transform: translate(-4px, 0);
  -ms-transform: translate(-4px, 0);
    transform: translate(-4px, 0);
  }

  40%, 60% {
  -webkit-transform: translate(4px, 0);
  -ms-transform: translate(4px, 0);
    transform: translate(4px, 0);
  }
}
.HomeDiv{
  width: 100%;
  height: 100%;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.HomeDiv>div{
  margin: 20px;
}
.HomeRight{
  margin-left: 0px!important;
}
.HomeRight>.ListedLobby{
  margin-top: 50px;

}
.LobbyList{
  margin-right: 20px!important;
  margin-top: 70px!important;
  min-width: 417px;

}
.CreateLobbyDiv{
  margin-left: 0px!important;
  width: 417px;
  height: 92px;
  background-color: rgba(0, 0 , 0, .18);
  border-radius: 5px;
  margin-top: 50px;
  position: relative;

}

.CreateLobbyDiv>ul{
  list-style: none;
  padding: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  top: 3px;
  position: relative;
 
}
.CreateLobbyDiv>ul>li{
  padding: 0px;
  margin: 0px;
  width: 100px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.1);
  margin-left: 3px;
  border-radius: 5px;
  color: white;
  font-family: 'Josefin Sans', sans-serif;
  line-height: 30px;
  cursor: pointer;
  font-size: 12px;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
}
.CreateLobbyDiv>ul>li:hover{
  background-color: rgba(255, 255, 255, 0.2);
  -ms-user-select: none;
      user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;



}

.Checkbox{
  position: absolute;
  left: 12px;
  bottom: 8px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.Checkbox>label{
  color: white;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 10px;
  -ms-user-select: none;
      user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  pointer-events: none;

}

.Checkbox>div{
  width: 12px;
  height: 12px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.1);
  margin-left: 3px;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
}

.Checkbox:hover>div{
  background-color: rgba(255, 255, 255, 0.2);
}
.CreateLobbyBtn{
  background-color: rgb(13, 124, 255);
  position: absolute;
  font-family: 'Josefin Sans', sans-serif;
  color: white;
  height: 24px;
  width: 103px;
  line-height: 24px;
  border-radius: 5px;
  font-size: 12px;
  -ms-user-select: none;
      user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  right: 4px;
  bottom: 6px;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
}
.CreateLobbyBtn:hover{
  opacity: .9;
}
.CreateLobbyDiv>ul>li.selected{
  background-color: rgba(255, 255, 255, 0.35);
}

.Checkbox.checked>div{
  background-color: rgba(0, 153, 255, 0.521);
}
.loaderdiv{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform:translate(-50%,-50%) ;
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;

}
.loaderdiv>div{
  width: 5px;
  height: 5px;
  margin: 1px;
  background-color: white;
}

.loaderdiv>:nth-child(1){
  -webkit-animation: blockspinner 1.5s none .25s infinite;
          animation: blockspinner 1.5s none .25s infinite;
}
.loaderdiv>:nth-child(2){
  -webkit-animation: blockspinner 1.5s none .5s infinite;
          animation: blockspinner 1.5s none .5s infinite;

}
.loaderdiv>:nth-child(3){
  -webkit-animation: blockspinner 1.5s none .75s infinite;
          animation: blockspinner 1.5s none .75s infinite;


}
.loaderdiv>:nth-child(4){
  -webkit-animation: blockspinner 1.5s none 1s infinite;
          animation: blockspinner 1.5s none 1s infinite;

}

@-webkit-keyframes blockspinner {
  0%, 100%{
  -webkit-transform: scale(1);
  -ms-transform:scale(1) ;
    transform: scale(1);
  }
  50%{
  -webkit-transform: scale(1.25);
  -ms-transform: scale(1.25);
    transform: scale(1.25);
  }
  
}

@keyframes blockspinner {
  0%, 100%{
  -webkit-transform: scale(1);
  -ms-transform:scale(1) ;
    transform: scale(1);
  }
  50%{
  -webkit-transform: scale(1.25);
  -ms-transform: scale(1.25);
    transform: scale(1.25);
  }
  
}

.CreateLobbyDiv>.CreateLobbyBtn>label{
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  pointer-events: none;
}
.CreateLobbyDiv.loading>.CreateLobbyBtn>label{
  opacity: 0;
}
.CreateLobbyDiv>.CreateLobbyBtn>div{
  -webkit-transition: opacity .25s ease-in;
  -o-transition: opacity .25s ease-in;
  transition: opacity .25s ease-in;
  opacity: 0;
}
.CreateLobbyDiv.loading>.CreateLobbyBtn>div{
 
  opacity: 1;
}
.CreateLobbyDiv.loading{
  pointer-events: none;
}
.NavBar{
  position: absolute;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 100;
}

.Logo{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  cursor: pointer;
  

}
.Logo>label{
  margin-left: 5px;
  font-family: 'Josefin Sans', sans-serif;
  color: white;
  font-size: 16px;
  position: relative;
  -webkit-transform: translateY(4px);
  -ms-transform: translateY(4px);
  transform: translateY(4px); 
  pointer-events: none;
  -ms-user-select: none;
      user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
main>div{
  -webkit-transition: opacity .25s ease-in;
  -o-transition: opacity .25s ease-in;
  transition: opacity .25s ease-in;
}
main{
  width: 100%;
  height: 100%;
}
main.transitioning>div{
  opacity: 0;
  pointer-events: none;
}
main>div{
  opacity: 1;

}

.Lobby{

  height: 100%;
  width: 100%;
}

.listed>.LobbyMenu{
  height: 92px;
  background-color: rgba(0, 0 , 0, .18);
}

.LobbyMenu{
  width: 417px;
  height: 187px;
  background-color: rgb(34, 34, 34);
  border-radius: 5px;
  position: relative;



}
.LobbyMenu>.players{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  position: relative;
  top: 3px;
  left: 1px;

}
.listed>.LobbyMenu>.players>div{
  background-color: rgba(255, 255, 255, 0.1);
  margin-left: 3px;
  border-radius: 5px;
  width: 100px;
 
  min-height: 30px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  line-height: 30px;
  color: white!important;
  font-family: 'Josefin Sans', sans-serif;
  -ms-user-select: none;
      user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  overflow: hidden;

}
.playercontainer{
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.LobbyMenu>.players>div>.plycard{
  background-color: rgba(255, 255, 255, 0.1);
  margin-left: 3px;
  border-radius: 5px;
  width: 100px;
  height: 30px;
  line-height: 30px;
  color: white!important;
  font-family: 'Josefin Sans', sans-serif;
  -ms-user-select: none;
      user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.LobbyMenu>.players>div>div{
  background-color: white;
  width: 30px;
  height: 4px;
  position: absolute;
  bottom: 0px;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
  border-radius: 10px;

}

.NicknameInput{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
 
  -webkit-box-align: start;
 
      -ms-flex-align: start;
 
          align-items: flex-start;
  top: 16px;
  position: relative;


}
.NicknameInput>div{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;


}
.NicknameInput>label{
  color: white;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 12px;
  margin-left: 5px;
  margin-bottom: 1px;
  -ms-user-select: none;
      user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;

  
 
}
.NicknameInput>div>input{
  font-family: 'Josefin Sans', sans-serif;
  border-style: none;
  background-color: rgba(255, 255, 255, 0.1);
  width: 305px;
  height: 40px;
  border-radius: 5px;
  color: white;
  margin-left: 3px;
  outline: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 5px;
  font-size: 16px;

}

.NicknameInput>div>.submitbtn{
  width: 100px;
  height: 40px;
  border-radius: 5px;
  background-color: rgb(0,148,255);
  margin-left: 3px;
  cursor: pointer;
  position: relative;
  opacity: .2;
  -webkit-transition: opacity .25s ease-in;
  -o-transition: opacity .25s ease-in;
  transition: opacity .25s ease-in;
  pointer-events: none;
}
.NicknameInput>div>.submitbtn.enabled{
  opacity: 1;
  pointer-events: all;
}

.submitbtn>img{
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}

.InviteBtn{
  width: 100px;
  height: 21px;
  border-radius: 5px;
  background-color: rgb(0,148,255);
  color: white;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 12px;
  line-height: 23px;
  position: relative;

  cursor: pointer;
  -ms-user-select: none;
      user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-transition: opacity .25s ease-in;
  -o-transition: opacity .25s ease-in;
  transition: opacity .25s ease-in;
}
.LobbyStatus{
  position: absolute;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 12px;
  right: 3px;
  bottom: 5px;
  color: white;
  -ms-user-select: none;
      user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
.ColorSelect{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  position: relative;
  top: 26px;

}

.ColorSelect>.choices{
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-transition: opacity .25s ease-in;
  -o-transition: opacity .25s ease-in;
  transition: opacity .25s ease-in;
  opacity: 0;
  pointer-events: none;
}


.ColorSelect>.SelectedColor1, .ColorSelect>.SelectedColor2{
  -webkit-transition: opacity .25s ease-in;
  -o-transition: opacity .25s ease-in;
  transition: opacity .25s ease-in;
  background-color: grey;
}

.ColorSelect>.SelectedColor1:hover, .ColorSelect>.SelectedColor2:hover, .ColorSelect>.choices>div:hover{
  opacity: .8;
  -webkit-transform: scale(.95);
  -ms-transform: scale(.95);
  transform: scale(.95);
}

.ColorSelect>.SelectedColor1, .ColorSelect>.SelectedColor2, .ColorSelect>.choices>div{
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  margin-right: 3px;
  margin-left: 3px;
  cursor: pointer;

}

.Color1{
  color: rgb(187,0,0)!important;
  background-color: rgb(187,0,0)!important;
  fill:rgb(187,0,0) !important;
  --rgb:187,0,0;
}
.Color2{
  color: rgb(20,0,255)!important;
  background-color: rgb(20,0,255)!important;
  fill:rgb(20,0,255) !important;
  --rgb:20,0,255;
}
.Color3{
  color:rgb(14,171,0)!important ;
  background-color: rgb(14,171,0)!important;
  fill:rgb(14,171,0) !important;
  --rgb:14,171,0;
}
.Color4{
  color: rgb(211,190,0)!important;
  background-color: rgb(211,190,0)!important;
  fill:rgb(211,190,0) !important;
  --rgb:211,190,0;
}
.Color5{
  color: rgb(211,114,0)!important;
  background-color: rgb(211,114,0)!important;
  fill: rgb(211,114,0)!important;
  --rgb:211,114,0;
}
.Color6{
  color:rgb(0,211,122)!important ;
  background-color: rgb(0,211,122)!important;
  fill: rgb(0,211,122)!important;
  --rgb:0,211,122;
}
.Color7{
  color:rgb(126,38,134)!important ;
  background-color: rgb(126,38,134)!important;
  fill: rgb(126,38,134)!important;
  --rgb:126,38,134;
}
.Color8{
  color: rgb(193,0,139)!important;
  background-color: rgb(193,0,139)!important;
  fill: rgb(193,0,139)!important;
  --rgb:193,0,139;
}
.Color9{
  color:rgb(45,13,174)!important ;
  background-color: rgb(45,13,174)!important;
  fill:rgb(45,13,174) !important;
  --rgb:45,13,174;
}
.Color10{
  color:white!important ;
  background-color: white!important;
  fill:white !important;
}

.ColorSelect.selecting>.SelectedColor1, .ColorSelect.selecting>.SelectedColor2{
  opacity: 0;
  pointer-events: none;

}
.ColorSelect.selecting>.choices{
  opacity: 1;
  pointer-events: all;


}
.LobbyMenuContainer{
  position: absolute;
  left: 50%;
  top: 40%;
  -webkit-transform:translate(-50%, -50%) ;
  -ms-transform:translate(-50%, -50%) ;
  transform: translate(-50%, -50%);
  width: 417px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.LobbyMenuContainer.listed{
  position: relative;
  left: unset;
  top: unset;
  -webkit-transform: unset;
  -ms-transform:unset ;
  transform: unset;
  margin-bottom: 10px;
  
}
.listed>.LobbyMenu>.players>div{
  font-size: 12px;
}

.InviteNotification{
  z-index: -1;
  top: 5px;
  background-color: rgb(0,148,255);
  color: white;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 14px;
  height: 45px;
  border-radius: 5px;
  line-height: 45px;
  position: relative;
  -webkit-transform: translateY(-55px);
  -ms-transform: translateY(-55px);
  transform: translateY(-55px);
  opacity: 0;
  width: 415px;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  -ms-user-select: none;
      user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;

  
}

.InviteNotification.show{
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
}

.players>.disabled{
  background-color: rgba(0, 0, 0, 0.7)!important;
}
.players>div>.disabled{
  background-color: rgba(0, 0, 0, 0.7)!important;
}

.choices>.selected{
  pointer-events: none;
  -webkit-transform: scale(.8);
  -ms-transform: scale(.8);
  transform: scale(.8);
  opacity: .5;
}

.NicknameInput>div>.submitbtn:hover{
  opacity: .8;
}


.nogames{
  width: 417px;
  height: 92px;
  background-color: rgba(0, 0 , 0, .18);
  border-radius: 5px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.nogames>label{
  font-size: 16px;
  color: white;
  font-family: 'Josefin Sans', sans-serif;
  -ms-user-select: none;
      user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
.refresh{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  -ms-user-select: none;
      user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  background-color: rgba(0, 0 , 0, 0);
  margin-top: 5px;
  border-radius: 5px;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  padding: 3px;
}

.refresh:hover{
  background-color: rgba(0, 0 , 0, .18);
}
.refresh>label{
  font-size: 12px;
  color: white;
  font-family: 'Josefin Sans', sans-serif;
  margin-left: 2px;
  pointer-events: none;

}
.listed>.LobbyMenu>label{
  font-size: 12px;
  position: absolute;
  color: white;
  font-family: 'Josefin Sans', sans-serif;
  -ms-user-select: none;
      user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  left: 3px;
  bottom: 5px;
}
.join{
  background-color: rgb(82, 200, 87);
}
.join>label{
  pointer-events: none;
}
.TurnIndicator{
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: white;
  position: absolute;
  opacity: .8;
}

.TurnIndicator.corner1{


  top: 0px;
  left: 0px;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}
.TurnIndicator.corner2{

  top: 0px;
  right: 0px;
  -webkit-transform: translate(50%,-50%);
  -ms-transform: translate(50%,-50%);
  transform: translate(50%,-50%);
}
.TurnIndicator.corner3{
  bottom: 0px;
  right: 0px;
  -webkit-transform: translate(50%,50%);
  -ms-transform: translate(50%,50%);
  transform: translate(50%,50%);
  
}
.TurnIndicator.corner4{
  bottom: 0px;
  left: 0px;
  -webkit-transform: translate(-50%,50%);
  -ms-transform: translate(-50%,50%);
  transform: translate(-50%,50%);

}

.piecemenu>.orientation>label{
  font-size: 8px;
  position: absolute;
  color: rgb(255, 255, 255);
  font-family: 'Josefin Sans', sans-serif;
  bottom: 1px;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  -webkit-transition: opacity .25s ease-in;
  -o-transition: opacity .25s ease-in;
  transition: opacity .25s ease-in;
  opacity: 0;

}

.waitforturn>.boardpiece>.PlayingPiece>.piecemenu>.orientation>label{
  opacity: 1;
}

.TurnTimerDiv{
position: absolute;
top: 726px;
left: 0px;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-orient: horizontal;
-webkit-box-direction: normal;
    -ms-flex-direction: row;
        flex-direction: row;
-webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;
  -webkit-transform: translateY(2px);
  -ms-transform: translateY(2px);
transform: translateY(2px);
-webkit-box-pack: center;
    -ms-flex-pack: center;
        justify-content: center;


}

.TurnTimerDiv>label{
  font-size: 12px;
  color: rgb(255, 255, 255);
  font-family: 'Josefin Sans', sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  -webkit-transition: opacity .25s ease-in;
  -o-transition: opacity .25s ease-in;
  transition: opacity .25s ease-in;
  bottom: 0px;
  margin-right: 5px;
  

  
}
.TurnTimerDiv>div{
  -webkit-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  transform: translateY(-4px);
}

.TurnTimerDiv>label{
  background-color: unset !important;
}



.players>div{
  color: white !important;
}


.base-timer__path-remaining {
  /* Just as thick as the original ring */
  stroke-width: 7px;

  /* Rounds the line endings to create a seamless circle */
  stroke-linecap: round;

  /* Makes sure the animation starts at the top of the circle */

  -webkit-transform: rotate(90deg);

      -ms-transform: rotate(90deg);

          transform: rotate(90deg);

  -webkit-transform-origin: center;

      -ms-transform-origin: center;

          transform-origin: center;

  /* One second aligns with the speed of the countdown timer */
  -webkit-transition: 1s linear all;
  -o-transition: 1s linear all;
  transition: 1s linear all;

  /* Allows the ring to change color when the color value updates */
  stroke: currentColor;
}

.base-timer__svg {
  /* Flips the svg and makes the animation to move left-to-right */
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}

/* Sets the containers height and width */
.base-timer {
  position: relative;
  height: 24px;
  width: 24px;
}

/* Removes SVG styling that would hide the time label */
.base-timer__circle {
  fill: none;
  stroke: none;
}

/* The SVG path that displays the timer's progress */
.base-timer__path-elapsed {
  stroke-width: 7px;
  stroke: rgb(0, 0, 0);
}

.base-timer__label {
  position: absolute;
  font-size: 12px;
  color: rgb(255, 255, 255);
  font-family: 'Josefin Sans', sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  /* Size should match the parent container */
  width: 24px;
  height: 24px;
  
  /* Keep the label aligned to the top */
  top: 0;
  
  /* Create a flexible box that centers content vertically and horizontally */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;

  /* Sort of an arbitrary number; adjust to your liking */
  font-size: 10px;
}

.Red{
 
  color: rgb(255, 255, 255);
}

.TurnIndicator.isturn{

  -webkit-animation: turnpulse 1s none 0s infinite;

          animation: turnpulse 1s none 0s infinite;
 


  
}

@-webkit-keyframes turnpulse {
  0%, 100%{
    opacity:1;

 
  }
  50%{
   
    opacity: .6;
  
   
    
  }
  
}

@keyframes turnpulse {
  0%, 100%{
    opacity:1;

 
  }
  50%{
   
    opacity: .6;
  
   
    
  }
  
}

.joinleave{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  position: absolute;
  bottom: 5px;
  right: 5px;

}

.joinleave>button{
  
    width: 100px;
    height: 21px;
    border-radius: 5px;
    background-color: rgb(0,148,255);
    color: white;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 12px;
    line-height: 23px;
    position: relative;
    font-weight: 700!important;
    cursor: pointer;
    -ms-user-select: none;
        user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -webkit-transition: opacity .25s ease-in;
    -o-transition: opacity .25s ease-in;
    transition: opacity .25s ease-in;
    border-style: none;
    transition: opacity .25s ease-in;
  
}

.joinleave>button:hover{
  opacity: .8;
}

.joinleave>.Reconnect{
margin-left: 3px;
}
.joinleave>.Surrender{
  background-color: white;
  color: black;

}
.pointslabel{
  background-color: unset !important;
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 14px;
  font-family: 'Josefin Sans', sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  color: white;
}
.lobbybtns{
  position: absolute;
  left: 2px;
  bottom: 5px;

  height: 21px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;

}
.lobbybtns>div{
  margin-right: 5px;
}
.friendsbtn{
  background-color: rgb(0,148,255);
  color: white;
  width: 30px;
  height: 21px;
  border-radius: 5px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: opacity .25s ease-in;
  -o-transition: opacity .25s ease-in;
  transition: opacity .25s ease-in;
}
.lobbybtns>div:hover{
  opacity: .8;
}
.shufflebtn{
  position: relative;
  outline: none;
  border-style: solid;
  border-radius: 5px;
  border-width: 0px;
  width: 50px;
  height: 21px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;

  cursor: pointer;
  -webkit-transition: opacity .25s ease-in;
  -o-transition: opacity .25s ease-in;
  transition: opacity .25s ease-in;

}
.shufflebtn>img{
  width: 16px;
  height: 16px;
}


.playercontainer>div.Team1{
  background-color: white!important;
}
.playercontainer>div.Team2{
  background-color: black!important;
}
.TurnIndicator.Team1{
  border-color: rgba(255, 255, 255, .6);
  border-width: 2px;
  border-style: solid;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.TurnIndicator.Team2{
  border-color: rgba(0, 0, 0, 0.6);
  border-width: 2px;
  border-style: solid;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
 
}
button>label{
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;

}

.joinleave.loading2>button{
  pointer-events: none;
}
.joinleave>button>.loaderdiv{
  opacity: 0;
}

.joinleave>button>*{
  -webkit-transition: opacity .25s ease-in;
  -o-transition: opacity .25s ease-in;
  transition: opacity .25s ease-in;
}

.joinleave.loading2>.Reconnect>label{
  opacity: 0;


}
.joinleave.loading2>.Reconnect>.loaderdiv{
  opacity: 1;
}

.joinleave.loading3>.Surrender>label{
  opacity: 0;


}
.joinleave.loading3>.Surrender>.loaderdiv{
  opacity: 1;
}
.joinleave>.Surrender>div>div{
  background-color: black;
}

.disabled{
  pointer-events: none;
}

.refresh{
  position: relative;
}
.refresh>.loaderdiv{
  opacity: 0;
}

.refresh>*{
  -webkit-transition: opacity .25s ease-in;
  -o-transition: opacity .25s ease-in;
  transition: opacity .25s ease-in;
}
.refresh.loading{
  pointer-events: none;
}

.refresh.loading>.loaderdiv{
  opacity: 1;

}
.refresh.loading>label{
  opacity: 0;
}
.refresh.loading>img{
  opacity: 0;
}
.hidelabel{
  display: none;
}

.Hand>.pieces>.nohand.shown{

  font-family: 'Josefin Sans', sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);


}

.colorpeekbtn>img{
   position: absolute;
   top: 50%;
   left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
   transform: translate(-50%, -50%);
  opacity: 0;

}
.colorpeekbtn{
  position: relative;
  
}

.colorpeekbtn.surrendered>img{
  opacity: 1;
}

.postgamecontainer{
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  z-index: 100;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  
}

.postgamemodal{
  width: 317px;
  height: 331px;
  background-color: rgba(0,0,0,.63);
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}

.postgamemodal>label{
  font-family: 'Josefin Sans', sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  color: white;
  font-size: 22px;
  margin-top: 21px;
  position: relative;
  font-weight: 400;
  margin-bottom: 18px;
}
.postgamemodal>.btns{
  position: absolute;
  bottom: 11px;
}

.postgamemodal>.btns>button{
  outline: none;
  border-style: solid;
  border-radius: 5px;
  border-width: 0px;
  width: 146px;
  height: 28px;
  background-color: rgb(0,148,255);
  font-family: 'Josefin Sans', sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  color: white;
  cursor: pointer;
  -webkit-transition: opacity .25s ease-in;
  -o-transition: opacity .25s ease-in;
  transition: opacity .25s ease-in;
  font-size: 14px;
  font-weight: 400;

  
}
.postgamemodal>.btns>.reviewbtn{
  background-color: white;
  color: black;
  margin-right: 4px;
}

.postgamemodal>.btns>button:hover{
opacity: .9;
}

.postgamemodal>.players>div{
  width: 298px;
  height: 33px;
  background-color: rgba(44,44,44,.38)!important;
  border-radius: 5px;
  margin-bottom: 11px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;



    font-family: 'Josefin Sans', sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  font-weight: 400;
  position: relative;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  pointer-events: none;
}
.postgamemodal>.players>div>label{
  -webkit-transform: translateY(1px);
  -ms-transform: translateY(1px);
  transform: translateY(1px);
  font-size: 14px;
}

.nametag{
  height: 30px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  justify-items: center;
  position: absolute;
  top: 0px;
  -webkit-transform: translateY(2px);
      -ms-transform: translateY(2px);
          transform: translateY(2px);

}
.players>.isuser>.nametag>.name{
  pointer-events: none!important;
 
}
.nametag>.score{
  position: absolute;
  right: 16px;
  pointer-events: none;
}
.postgamemodal>.players>div.islocalplayer{
  outline: #00A4FF;
  outline-width: 1px;
  outline-style: solid;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  pointer-events: none;

}
.postgamemodal>.players>div>.nametag>div{
  height: 12px;
  width: 12px;
  border-radius: 100%;
  margin-left: 10px;
  background-color: white;
  margin-right: 6px;
  pointer-events: none;
}
.nametag>img{
  margin-bottom: 2px;
  margin-left: 2px;
  display: none;
}

.postgamemodal>.players>div.winner{
  background-color: rgba(var(--rgb),.1) !important;

}

.postgamemodal>.players>div>img{
position: absolute;
  -webkit-transform: translate(40%,-30%);
  -ms-transform: translate(40%,-30%);
transform: translate(40%,-30%);
right: 0px;
top: 0px;
}
.kickdiv{
  background-color: white;
  width: 20px!important;
  height: 20px!important;
  border-radius: 100%;
  position: absolute;
  cursor: pointer;
  top: 0px!important;
  -webkit-transform: translate(0,-75%)!important;
      -ms-transform: translate(0,-75%)!important;
          transform: translate(0,-75%)!important;
  opacity: 0;


  -webkit-transition: opacity .25s ease-in;


  -o-transition: opacity .25s ease-in;


  transition: opacity .25s ease-in;
 
}


.kickdiv>img{

  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;

}

.players>:nth-child(1){
  z-index: 4;
}

.players>:nth-child(2){
  z-index: 3;
}
.players>:nth-child(3){
  z-index: 2;
}
.players>:nth-child(4){
  z-index: 1;
}


.players:hover>div>.kickdiv{
  opacity: 1!important;


}

.StatusNotification{
  background-color: rgb(13, 124, 255);
  position: relative;
  bottom: -50px;
  height: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  opacity: 0;
  left: 50%;
  -webkit-transform: translate(-50%);
      -ms-transform: translate(-50%);
          transform: translate(-50%);
  border-radius: 5px;
  padding: 10px;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  line-height: 16px;

}
.StatusNotification.show{
  bottom: 20px;
  opacity: 1;
}


.StatusNotification>label{

  color: rgb(255, 255, 255);
  font-family: 'Josefin Sans', sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  font-size: 14px;
}


.AccountDashboard{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 417px;
  height: 120px;
  background-color: rgba(0, 0 , 0, .18);
  border-radius: 5px;
  margin-top: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  

}
.UserInfoDashboard{
  margin-left: 4px;
  position: relative;

  width: 192px;
  height: 108px;
}

.SignInContainer{
  position: absolute;
  left: 0px;
  width: 192px;
  height: 108px;
  cursor: pointer;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
}
.SignInContainer:hover{
  -webkit-transform: scale(.98);
  -ms-transform: scale(.98);
  transform: scale(.98);
}

.SignInContainer>div{
 width: 192px;
 height: 108px;
 background: -o-linear-gradient(135deg, #29B2FF 20%, #0081CA  );
 background: linear-gradient(315deg, #29B2FF 20%, #0081CA  );
 border-radius: 5px;
 position: relative;



}
.SignInContainer>div>div>.needmoreblokio{
  color: rgba(255, 255, 255,.42);
  font-size: 13px;
  width: 100%;
  text-align: left;
  display: block;
}

.SignInContainer>div>div>label{
  color: rgba(255, 255, 255);
  font-family: 'Josefin Sans', sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  font-size: 17px;
  pointer-events: none;
}

.SignInContainer>div>div{
  position: absolute;
  width: 160px;
  text-align: left;
  bottom: 6px;
  left: 6px;
}
.SignInContainer>div>div>img{
  position: absolute;
 bottom: 0px;
 -webkit-transform: translateY(4px);
     -ms-transform: translateY(4px);
         transform: translateY(4px);
}
.MatchHistoryContainer{
  position: absolute;
  right: 5px;
  height: 108px;
  width: 202px;
  bottom: 6px;

}

.MatchHistoryContainer>label{
  color: rgba(255, 255, 255);
  font-family: 'Josefin Sans', sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  font-size: 14px;
  pointer-events: none;
  position: absolute;
  left: 3px;
  top: 3px;

}
.MatchHistoryPreview{
  width: 202px;
  height: 90px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  position: absolute;
  bottom: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  
  
}
.MatchHistoryContainer>button{
  -ms-user-select: none;
      user-select: none;
  font-family: 'Josefin Sans', sans-serif;
  color: white;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  border-style: solid;
  border-width: 0px;
  border-radius: 10px;
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 12px;
  height: 14px;
  line-height: 14px;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  
  
}
.MatchHistoryContainer>button:hover{
  background-color: rgba(255, 255, 255, 0.2);
}
.MatchHistoryPreview> span>span{
  position: absolute;

  left: 0px;
  top: 0px;
}

.UserInfo{
  width: 100%;
  height: 100%;

  
}

.namediv{
  width: 100%;
  height: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  line-height: 30px;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  cursor: pointer;
  position: relative;
}
.UserInfo.settingsopen>.namediv{
  background-color: rgba(255, 255, 255, 0.3);
}
.namediv:hover{
  background-color: rgba(255, 255, 255, 0.2);

}

.namediv>label{
  left: 4px;
  position: absolute;
  
  color: rgba(255, 255, 255);
  font-family: 'Josefin Sans', sans-serif;

  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  -webkit-transform: translateY(2px);
      -ms-transform: translateY(2px);
          transform: translateY(2px);
  pointer-events: none;
  font-weight: 400;
}
.namediv>img{
  right: 4px;
  position: absolute;

}
.bottominfo{
  position: absolute;
  bottom: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;

}


.bottominfo>div>label{
  color: rgba(255, 255, 255);
  font-family: 'Josefin Sans', sans-serif;

  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  font-weight: 400;
  font-size: 12px;



}
.bottominfo>div{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

}


.bottominfo>.ratingdiv{
  width: 109px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: 4px;
}

.bottominfo>.friendsdiv{
  width: 80px;
  height:-webkit-fit-content;
  height:-moz-fit-content;
  height:fit-content;
}

.bottominfo>div>div{
  background-color: rgba(255, 255, 255, 0.1);
  height: 31px;
  width: 100%;
  border-radius: 5px;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  cursor: pointer;
}
.bottominfo>div>div:hover{
  background-color: rgba(255, 255, 255, 0.2);

}

.copyfriendcode{
  width: 80px;
  background-color: rgba(255, 255, 255, 0.1);
  height: 20px;
  line-height: 20px;
  border-radius: 5px;
  top: 10px;
  position: relative;
  cursor: pointer;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;

}

.copyfriendcode:hover{
  background-color: rgba(255, 255, 255, 0.2);
}
.copyfriendcode>img{
  position: absolute;
  right: 3px;
}

.copyfriendcode>label{
  color: rgba(255, 255, 255);
  font-family: 'Josefin Sans', sans-serif;

  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  font-weight: 400;
  font-size: 10px;
  pointer-events: none;
  text-align: center;

  margin-left: 3px;
  margin-right: 5px;
  -webkit-transform: translateY(2px);
      -ms-transform: translateY(2px);
          transform: translateY(2px);



}

.modewinrates{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  position: absolute;
  bottom: 1px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.modewinrates>div{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
-webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;
-webkit-box-pack: center;
    -ms-flex-pack: center;
        justify-content: center;
margin-left: 3px;

}
.modewinrates>div>label{
  -webkit-transform: translateY(2px);
      -ms-transform: translateY(2px);
          transform: translateY(2px);
}


.ratingdiv>div{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.modewinrates>div>label{
  color: rgba(255, 255, 255);
  font-family: 'Josefin Sans', sans-serif;

  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  font-weight: 400;
  font-size: 10px;
  pointer-events: none;
  text-align: center;
}

.standardrating{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;

  top: 4px;
}

.standardicondiv>div{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin-bottom: 1px;



}

.standardicondiv{
  -webkit-transform: translateY(-1px);
      -ms-transform: translateY(-1px);
          transform: translateY(-1px);
  margin-right:2px;
}

.standardicondiv>div>div{
  width: 5px;
  height: 5px;
  background-color: #00A4FF;
  margin-left: 1px;
}


.standardrating>label{
  color: #00A4FF;
  font-family: 'Josefin Sans', sans-serif;

  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  font-weight: 600;
  font-size: 16px;
  pointer-events: none;
}
.modewinrates>div>div{
  height: 11px;
  width: 11px;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.duelsicon{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.duelsicon>div{
  width: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  margin-right: 1px;
  
}
.duelsicon>div:nth-child(2){
  width: 5px;
  background-color: rgba(255, 255, 255, 0.2);
  margin-right: 1px;
  
}
.teamsicon{
  margin-right: 1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.teamsicon>div{
  width: 5px;
  margin-right: 1px;
}

.teamsicon>div>div{
  background-color: rgba(255, 255, 255, 0.1);
  width: 5px;
  height: 5px;
  margin-bottom: 1px;

}
.teamsicon>div:nth-child(2)>div{
  background-color: rgba(255, 255, 255, 0.2);
}

.friendsdiv>div{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}

.friendsdiv>div>div{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  left: 4px;
}


.friendsdiv>div>label{
  color: white;
  font-family: 'Josefin Sans', sans-serif;

  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  font-weight: 600;
  font-size: 16px;
  pointer-events: none;
  -webkit-transform: translateY(2px);
      -ms-transform: translateY(2px);
          transform: translateY(2px);
  position: absolute;
  right: 6px;

}
.friendsdiv>div>div>label{

  color: #00A4FF;
  font-family: 'Josefin Sans', sans-serif;

  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  font-weight: 600;
  font-size: 16px;
  pointer-events: none;
  -webkit-transform: translateY(2px);
      -ms-transform: translateY(2px);
          transform: translateY(2px);
  margin-right: 2px;


}

.friendsdiv>div>div>div{
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: #00A4FF;
}


.dashboardmatch{

  background-color: rgba(255, 255, 255, .1);
  width: 98%;
  height: 20px;
  border-radius: 5px;
  position: relative;
  margin-top: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
}

.dashboardmatch:hover{
  background-color: rgba(255, 255, 255, 0.2);
}

.dashboardmatch>label{
  color: white;
  font-family: 'Josefin Sans', sans-serif;

  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  font-weight: 400;
  font-size: 12px;
  pointer-events: none;
vertical-align: middle;
}

.dashboardmatch>div{
  border-radius: 100%;
  width: 12px;
  height: 12px;
  margin-left: 4px;
  background-color: rgb(130,130,130);
  margin-right: 4px;

}

.dashboardmatch>.matchdate{
  position: absolute;
  right: 2px;

}
.matchwon{
  background-color: #00A4FF !important;
}

.UserInfoDiv{
  opacity: 1;
  pointer-events: all;

  -webkit-transition: all .25s ease-in;

  -o-transition: all .25s ease-in;

  transition: all .25s ease-in;
  position: absolute;
  bottom: 0px;
  height: 80px;
  width: 100%;
}

.UserInfo.settingsopen>.UserInfoDiv{
  opacity: 0;
  pointer-events: none;
  -webkit-transform: scale(.5);
      -ms-transform: scale(.5);
          transform: scale(.5);
}

.UserInfo.settingsopen>.SettingsDiv{
  opacity: 1;
  pointer-events: all;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);

}
.SettingsDiv{
  opacity: 0;
  pointer-events: none;

  position: absolute;
  width: 192px;
  -webkit-transform: scale(.5);
      -ms-transform: scale(.5);
          transform: scale(.5);
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
}

.SettingsOptions>div{
  width: 100%;
  height: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  line-height: 30px;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  cursor: pointer;
  margin-top: 2px;

}

.SettingsOptions>div:hover{
  background-color: rgba(255, 255, 255, 0.2);
}

.SettingsOptions>div>label{
  
    left: 4px;
    position: absolute;
    
    color: rgba(255, 255, 255);
    font-family: 'Josefin Sans', sans-serif;
  
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
        user-select: none;
    -webkit-transform: translateY(2px);
        -ms-transform: translateY(2px);
            transform: translateY(2px);
    pointer-events: none;
    font-weight: 400;

}

img{
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
}

.MatchHistoryPreview>label{
  color: rgba(255, 255, 255);
  font-family: 'Josefin Sans', sans-serif;

  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  pointer-events: none;
  font-size: 12px;
  font-weight: 400;
  top: 50%;
  position: absolute;

}
.ratingdiv>div{
  position:relative !important;

}

.react-loading-skeleton{
  position: absolute !important;
  left: 0px;
  top: 0px;
}

.UserInfo.disabled{
  pointer-events: none !important;
}
.UserInfo.disabled>.UserInfoDiv{
  pointer-events: none !important;
}

.SettingsDiv>.namechangediv{

  position: absolute !important;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 80px ;

  cursor: default;
  margin-top: 2px;

}
.SettingsOptions{
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;

}
.SettingsDiv.shownamechange>.SettingsOptions{
  pointer-events: none;
  opacity: 0;
  -webkit-transform: scale(.5);
      -ms-transform: scale(.5);
          transform: scale(.5);

}
.SettingsDiv>.namechangediv{
  opacity: 0;
  -webkit-transform: scale(.5);
      -ms-transform: scale(.5);
          transform: scale(.5);
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  pointer-events: none;
}

.SettingsDiv.shownamechange>.namechangediv{
  pointer-events: all;
  opacity: 1;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}

.namechangediv>.backbtn{
  width: 100%;
  height: 26px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  bottom: 0px;
  position: absolute;
  cursor: pointer;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
}
.namechangediv>.backbtn:hover{
background-color: rgba(255, 255, 255, 0.2);;
}

.backbtn>label{
  color: rgba(255, 255, 255);
  font-family: 'Josefin Sans', sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  pointer-events: none;
  font-size: 14px;
  font-weight: 400;

  

}

.blokioinput{
  position: absolute;
  width: 67%;
  bottom: 0px;
  height: 40px;
  left: 0px;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;

}
.blokioinput>input:hover{
  border-color:rgba(255, 255, 255, 0.1);
}

.blokioinput>label{
  position: absolute;
  color: rgba(255, 255, 255);
  font-family: 'Josefin Sans', sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  pointer-events: none;
  font-size: 14px;
  font-weight: 400;
  -webkit-transform: translateY(25%);
      -ms-transform: translateY(25%);
          transform: translateY(25%);
  height: 65%;
  bottom: 0px;
  left: 2%;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  
}

.blokioinput>input:focus~label{

  bottom: 35%;
  -webkit-transform:translateX(-10%) scale(.8);
      -ms-transform:translateX(-10%) scale(.8);
          transform:translateX(-10%) scale(.8);
}


.blokioinput>input{
  position: absolute;
  width: 100%;
  height: 65%;
  bottom: 0px;
  border-radius: 5px;
  outline: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-style: solid;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0);
  color: white;
  vertical-align: middle;
  font-family: 'Josefin Sans', sans-serif;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
}

.blokioinput.textinside>label{
  bottom: 35%;
  -webkit-transform:translateX(-10%) scale(.8);
      -ms-transform:translateX(-10%) scale(.8);
          transform:translateX(-10%) scale(.8);
}
.blokioinput>input:focus{
  border-color:rgba(255, 255, 255, 0.1);
}

.namechangediv>.inputandbtn>.submitbtn{
  background-color: #00A4FF;
  width: 60px;
  height: 26px;
  border-radius: 5px;
  position: absolute;
  right: 0px;
  opacity: .2;
  -webkit-transition: opacity .25s ease-in;
  -o-transition: opacity .25s ease-in;
  transition: opacity .25s ease-in;
  pointer-events: none;
}
.namechangediv>.inputandbtn{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  margin-top: 24px;
  width: 100%;
  height: 26px;
}

.blokioinput.textinside~.submitbtn{
  opacity: 1;
  cursor: pointer;
  pointer-events: all;
}
.blokioinput.error~.submitbtn{
  opacity: .2;
  pointer-events: none;
}
.inputandbtn.sending{
  pointer-events: none!important;

}
.inputandbtn.sending>div{
  pointer-events: none!important;

}
.inputandbtn>.submitbtn>.loaderdiv{
  opacity: 0;
  -webkit-transition: opacity .25s ease-in;
  -o-transition: opacity .25s ease-in;
  transition: opacity .25s ease-in;
}
.inputandbtn>.submitbtn>img{
  opacity: 1;
  -webkit-transition: opacity .25s ease-in;
  -o-transition: opacity .25s ease-in;
  transition: opacity .25s ease-in;
}
.inputandbtn.sending>.submitbtn>img{
  opacity: 0;

}


.inputandbtn.sending>.submitbtn>.loaderdiv{
  opacity: 1;

}
.inputandbtn>.submitbtn:hover{
  opacity: .8;
}
.blokioinput.error>label{
color: red;
}

.blokioinput.error>input{
  border-color:rgba(163, 0, 0, 0.1)!important;
  background-color: rgba(163, 0, 0, 0.1)!important;
  color: red;
}

.MatchDiv{
  width: 540px;
  height: 110px;
  background-color: #1B1B1B;
  border-radius: 5px;


}

.MatchDiv>.Data{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  top: 5px;
  position: absolute;
  left: 5px;
}


.MatchDiv>.Data>label{
  margin-bottom: 3px;

  color: rgba(255, 255, 255);
  font-family: 'Josefin Sans', sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  pointer-events: none;
  font-size: 12px;
  -webkit-transform: translateY(2px);
      -ms-transform: translateY(2px);
          transform: translateY(2px);



}
.MatchDiv>.Data>.Win{
  color: #00A4FF;
}
.MatchDiv>.Data>.Loss{
  color: #c40000;
}



.MatchDiv>.Data>label:nth-child(1){

  width: 30px;
  height: 16px;

  

}

.MatchDiv>.Data>label:nth-child(2){

  width: 60px;
  height: 16px;

  

}

.MatchDiv>.Data>label:nth-child(3){

  width: 80px;
  height: 16px;

  

}

.MatchDiv>.Data>label>span>.react-loading-skeleton{
  position: relative!important;
}

.MatchDiv>.ScoreBoard{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  position: absolute;
  bottom: 3px;

  width: 99%;
  left: 50%;
  height: 34px;
  -webkit-transform: translate(-50%);
      -ms-transform: translate(-50%);
          transform: translate(-50%);
}

.MatchDiv>.ScoreBoard>div{
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  border-radius: 5px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  margin-left: 1px;
  margin-right: 1px;
}

.MatchDiv>.RecapBtn{
  width: 40px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  right: 5px;
  top: 5px;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  cursor: pointer;
}
.MatchDiv>.RecapBtn:hover{
  background-color: rgba(255, 255, 255, 0.2);
}


.ScoreBoardTag{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: opacity .25s ease-in;
  -o-transition: opacity .25s ease-in;
  transition: opacity .25s ease-in;
  pointer-events: none;

}

.ScoreBoardTag>.Colors{
  width: 12px;
  height: 12px;
  margin-left: 5px;
  position: relative;

}
.ScoreBoardTag>.Colors>div{
  width: 100%;
  height: 100%;

  position: absolute;
  border-radius: 100%;
}
.ScoreBoardTag>.Colors>div:nth-child(2){
  width: 100%;
  height: 50%;
  position: absolute;
  border-radius: 6px 6px 0px 0px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transform: translate(3px,3px) rotate(90deg);
      -ms-transform: translate(3px,3px) rotate(90deg);
          transform: translate(3px,3px) rotate(90deg);
 
  

  
}
.ScoreBoardTag>.NameScore{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-left: 5px;




}

.NameScore>div{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.ScoreBoardTag>.NameScore>label, .ScoreBoardTag>.NameScore>div>label{
  color: rgba(255, 255, 255);
  font-family: 'Josefin Sans', sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  pointer-events: none;
  font-size: 14px;
  -webkit-transform: translateY(2px);
      -ms-transform: translateY(2px);
          transform: translateY(2px);
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  position: relative;
  margin-right: 1px;
}

.ScoreBoardTag.highlighted{
  outline: #00A4FF;
  outline-width: 1px;
  outline-style: solid;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  pointer-events: none!important;
  
}

.ScoreBoardTag.won{
    background-color: rgba(var(--rgb),.1) !important;
}

.ScoreBoardTag.isuser{
  cursor: pointer;
  pointer-events: all;
}
.ScoreBoardTag.isuser:hover{

  opacity: .8;
}

.DashboardMatchViewer{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.DashboardMatchViewer>.MatchDiv{
  position: absolute;
  -webkit-transform: translate(-50%,-50%) scale(.76);
      -ms-transform: translate(-50%,-50%) scale(.76);
          transform: translate(-50%,-50%) scale(.76);
  left: 50%;
  top: 60%;
 

}
.DashboardMatchViewer>img{
  position: absolute;
  top: 5px;
  left: 5px;
  cursor: pointer;
}

.ProfileDiv{
  width: 560px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  position: absolute;
  left: 50%;
  top: 70px;
  -webkit-transform: translate(-50%);
      -ms-transform: translate(-50%);
          transform: translate(-50%);
  height: 90%;
  color: rgba(255, 255, 255);
  font-family: 'Josefin Sans', sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;


}

.ProfileDiv>.Head{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  height: 24px;
}
.ProfileDiv>.Head>label{
  margin-right: auto;
  font-size: 16px;
  -webkit-transform: translateY(10px);
      -ms-transform: translateY(10px);
          transform: translateY(10px);
}
.ProfileDiv>.Head>div{
  background-color: #00A4FF;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  
}
.ProfileDiv>.Head>div>label{
  pointer-events: none;
  vertical-align: middle;
  font-size: 12px;
}

.ProfileDiv>.StatsDiv{
  width: 100%;
  height: 140px;
  background-color: #222222;
  margin-top: 5px;
  border-radius: 5px;
}

.ProfileDiv>.StatsDiv{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  position: relative;
}

.ProfileDiv>.StatsDiv>label{
  margin-top: 5px;
  margin-left: 5px;
  font-size: 12px;
}
.StatsDiv>div{
 
  -webkit-box-orient: horizontal;
 
  -webkit-box-direction: normal;
 
      -ms-flex-direction: row;
 
          flex-direction: row;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  bottom: 0px;
  position: absolute;
  bottom: 0px;
  padding: 2px;
  padding-bottom: 4px;
  padding-top: 0px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 110px;


}
.StatsDiv>div>div{
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;

  
  width: 40px;

  margin-left: 2px;
  margin-right: 2px;
  position: relative;
 
}

.StatsDiv>div>div>div:nth-child(1){



font-size: 12px;
position: absolute;
left: 3px;
top: 3px;
 
}

.StatsDiv>div>div>div:nth-child(2){

  background-color: #1B1B1B;

  width: 100%;
  height: 92px;
  position: absolute;
  bottom: 0px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
 
}

.StandardDiv{

  -webkit-box-flex: 0 !important;

      -ms-flex: 0 171px !important;

          flex: 0 171px !important;
}


.StatsDiv>div>div>div>div{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  position: absolute;
  bottom: 0px;
  padding-bottom: 2px;
  padding-left: 2px;
  padding-right: 2px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;

  
  
}
.StatsDiv>div>div>div>div>div{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-right: 1px;
  margin-left: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 24px;
}

.StatsDiv>div>div>div>div>div>label{
  font-size: 12px;
  position: absolute;
}
.StatsDiv>div>div>div>div>div>label>span{

  color: #00A4FF;
}
.StatsDiv>div>div>div>div>div>label:nth-last-child(2){
  top: -12px;
}
.RatingDiv>label{
  color: #00A4FF;
}
.StatsDiv>div>div>div:nth-child(2)>label{
  font-size: 36px;
  top: 16px;
  position: absolute;
}
.MatchHistoryDiv{
  background-color: #222222;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-top: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 500px;
  position: relative!important;

}


.MatchHistoryDiv>.Nav{
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 5px;
  

}
.MatchHistoryDiv>.Nav>label{
  margin-right: auto;
  font-size: 12px;

}

.MatchHistoryDiv>.MatchesScroll>div{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  position: relative;

  margin-left: 4px;
  margin-right: 4px;
  border-radius: 5px;
  height: 100%;
  margin-bottom: 5px;



}
.MatchHistoryDiv>.MatchesScroll>.Matches>.MatchDiv{
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  width: 100%;

}

.MatchesScroll{

  height: -webkit-fit-content;

  height: -moz-fit-content;

  height: fit-content;

  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;


}
.MatchesScroll::-webkit-scrollbar{
  display: none;

}
.MatchesScroll>.MatchesLoading{
  position: absolute!important;
height: 96%!important;
width: 99%;

left: -1px!important;

}
.MatchesScroll>.Matches{

  height: -webkit-fit-content!important;

  height: -moz-fit-content!important;

  height: fit-content!important;
  
 
}
.players>div>.userinfo{
  background-color: rgba(0, 0, 0, 0)!important;
  border-radius: 5px!important;
  width: 100%!important;
  height: 50px!important;
  position: absolute;
  left: 0px!important;
  margin: 0px!important;
  bottom: 0px;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
}
.players>div.selected{
  height: 80px!important;


}
.userinfo>.stats{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  font-size: 12px;


}
.userinfo>button{

  -ms-user-select: none;

      user-select: none;
  font-family: 'Josefin Sans', sans-serif;
  color: white;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  border-style: solid;
  border-width: 0px;
  border-radius: 5px;
  right: 0px;
  font-size: 12px;
  height: 20px;
 
  -webkit-transition: all .25s ease-in;
 
  -o-transition: all .25s ease-in;
 
  transition: all .25s ease-in;
  position: absolute;
  left: 3px;
  bottom: 3px !important;
  width: 80px;

}
.userinfo>button:hover{

  background-color: rgba(255, 255, 255, 0.2);
}
.userinfo>label{
  bottom: 20px;
  right: 3px;
  position: absolute;
}
.userinfo>.stats{
  position: absolute;
  right: 3px;
  bottom: 3px;

}
.userinfo>.stats>div{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin-left: 3px;
}
.userinfo>.stats>div>label{

  margin-left: 1px;
}
.userinfo>.stats>div:nth-child(4){
margin-left: 6px;
color: #00A4FF;
}
.postgamemodal>.players>div.selected>.userinfo{

  opacity: 1;
  
  pointer-events: all;
}
.players>div.isuser{
  pointer-events: all;
  cursor: pointer;

}
.players>div.isuser>.nametag>img{
  display: block;
}
.players>div.selected{
  cursor: unset;
}

.DashboardFriendsList{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;

}
.DashboardFriendsList>img{
  position: absolute;
  top: 5px;
  left: 5px;
  cursor: pointer;
  
}
.DashboardFriendsList>.TabBtns{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  position: absolute;
  top: 3px;
  left: 28px;

}

.TabBtns>button{
  -ms-user-select: none;
      user-select: none;
  font-family: 'Josefin Sans', sans-serif;
  color: white;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  border-style: solid;
  border-width: 0px;
  border-radius: 5px;
  right: 0px;
  font-size: 12px;
  width: 90px;
  height: 24px;
    font-size: 14px;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  margin-left: 5px;


}
.TabBtns>button:hover{
  background-color: rgba(255, 255, 255, 0.2);
}
.TabBtns>button.selected{
  background-color: rgba(255, 255, 255, 0.3);
}

.DashboardFriendsList>.ContentContainer{
   background-color: #1B1B1B;
   width: 98%;
   height: 86px;
   position: absolute;
  bottom: 4px;
  left: 50%;
  -webkit-transform: translate(-50%);
      -ms-transform: translate(-50%);
          transform: translate(-50%);
  font-weight: 400;
  -ms-user-select: none;
      user-select: none;
  font-family: 'Josefin Sans', sans-serif;
  color: white;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;


}
.DashboardFriendsList>.ContentContainer>.Friends{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  justify-items: flex-start;

  height: 86px;
  width: 100%;
  position: absolute;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;

}
.DashboardFriendsList>.ContentContainer.showRequests>.Friends{
  opacity: 0;
  pointer-events: none;
  -webkit-transform: scale(.5);
      -ms-transform: scale(.5);
          transform: scale(.5);
}

.DashboardFriendsList>.ContentContainer>.Friends::-webkit-scrollbar{
  display: none;
}
.DashboardFriendsList>.ContentContainer>.Friends>.Friend{
  width: 98%;
  height: 26px;
  background-color: rgba(255, 255, 255, 0.1);
  font-weight: 400;
  border-radius: 5px;
  margin-top: 2px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  

}
.Friend>div{
  width: 12px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  position: absolute;
  left: 5px;
}
.Friend>label{
  position: absolute;
  left: 22px;
 

}
.Friend>.btns{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: unset;
  right: 0px!important;
  position: absolute!important;
  
  width: -webkit-fit-content;
  
  width: -moz-fit-content;
  
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: unset;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;

}


.Friend>.btns>button{
  -ms-user-select: none;
      user-select: none;
  font-family: 'Josefin Sans', sans-serif;
  color: white;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  border-style: solid;
  border-width: 0px;
  border-radius: 5px!important;
  right: 0px;
  font-size: 12px;
  width: 70px;
  height: 20px;
    font-size: 14px;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  margin-right: 2px;
}
.Friend>.messagebtn{

  right: 75px;
}
.Friend>.btns>.removebtn{

  right: 0px;
  background-color: rgba(255, 0, 0, 0.2);
}
.Friend>.btns>.messagebtn:hover{
  background-color: rgba(255, 255, 255, 0.2);
}
.Friend>.btns>.removebtn:hover{
  opacity: .9;
  background-color: rgba(255, 0, 0, 0.4);
}


.Friend>.confirmationbtns{
  opacity: 0;
  -webkit-transform: scale(.5);
      -ms-transform: scale(.5);
          transform: scale(.5);
  pointer-events: none;

}


.Friend.removeopen>.confirmationbtns{
  opacity: 1;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  pointer-events: all;

}
.Friend.removeopen>.defaultbtns{
  opacity: 0;
  -webkit-transform: scale(.5);
      -ms-transform: scale(.5);
          transform: scale(.5);
  pointer-events: none;

}
.ContentContainer>.Requests{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  -webkit-transform: scale(.5);
      -ms-transform: scale(.5);
          transform: scale(.5);
  opacity: 0;
  pointer-events: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 2px;

}

.ContentContainer.showRequests>.Requests{
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  opacity: 1;
  pointer-events: all;
}
.ContentContainer>.Requests>div{
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  
  position: relative;
  height: 100%;

  border-radius: 5px;

}
.ContentContainer>.Requests>.Incoming{
  margin-right: 4px;
}
.ContentContainer>.Requests>div>.header{

  width: 100%;
  height: 26px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  line-height: 26px;
}
.ContentContainer>.Requests>div>.container{
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  height: 56px;
  width: 100%;
}
.ContentContainer>.Requests>div>.container::-webkit-scrollbar{
  display: none;
}
.ContentContainer>.Requests>div>.container>div{
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 100%;
  height: 26px;
  background-color: rgba(255, 255, 255, 0.1);
  margin-top: 2px;
  border-radius: 5px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.Requests>div>.container>div>label{
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  width: 168px;
  left: 5px;
  text-align: left;


}

.Requests>div>.container>div>div{
  width: 22px;
  height: 22px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  position: absolute;
  right: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  cursor: pointer;
}
.Requests>div>.container>div>div:hover{
  background-color: rgba(255, 255, 255, 0.2);

}

.Incoming>.container>div>label{

  width: 140px!important;
}
.Incoming>.container>div>div:nth-child(2){
  right: 30px!important;
}
.Requests>div>.container>div>div>img{
  pointer-events: none;
}
.Requests>div>.container>div>div.loading{
  pointer-events: none;
}

.Requests>div>.container>div>div.loading>img{
  opacity: 0;

}
.Requests>div>.container>div>div.loading>.lds-ring{
  display: block;
  opacity: .8;

}

.lds-ring {

  opacity: 0;
  display: none;
  position: absolute;
  width: 80px;
  height: 80px;

  -webkit-transform: scale(.24);

      -ms-transform: scale(.24);

          transform: scale(.24);
  

}
.lds-ring div {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #00A4FF transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.verifyemailbutton{
  cursor: default!important;
}
.SignInContainer.showverify{
  cursor: default!important;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}

.verifyemailbutton>div{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.verifyemailbutton>div>div{
  -ms-user-select: none;
      user-select: none;
  font-family: 'Josefin Sans', sans-serif;
  color: white;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  font-size: 12px;
  font-weight: 400;
  background-color: rgba(255, 255, 255, 0.2);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px;
  border-radius: 50px;
  margin-right: 5px;
  cursor: pointer;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
}
.verifyemailbutton>div>div:hover{
  background-color: rgba(255, 255, 255, 0.4);
}
.Head>div{
position: relative;
-webkit-transition: all .25s ease-in;
-o-transition: all .25s ease-in;
transition: all .25s ease-in;
}
.Head>div>.loaderdiv{
  -webkit-transition: opacity .25s ease-in;
  -o-transition: opacity .25s ease-in;
  transition: opacity .25s ease-in;
}
.Head>div>label{
-webkit-transition: opacity .25s ease-in;
-o-transition: opacity .25s ease-in;
transition: opacity .25s ease-in;
}
.Head>div:hover{
  opacity: .8;
}
.Head>div.loading{
  pointer-events: none;
}
.Head>div.loading>label{
  opacity: 0;
}
.Head>div.loading>.loaderdiv{
  opacity: 1;
}
.Head>div>.loaderdiv{
  opacity: 0;
}

.FriendInviteModalContainer{
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  position: relative;
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: opacity .25s ease-in;
  -o-transition: opacity .25s ease-in;
  transition: opacity .25s ease-in;
  opacity: 0;
  pointer-events: none;
}
.FriendInviteModalContainer.shown{
pointer-events: all;
opacity: 1;
}
.FriendInviteModal{
  width: 370px;
  height: 300px;
  background-color: rgb(26, 26, 26);
  border-radius: 5px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;

  color: rgba(255, 255, 255);
  font-family: 'Josefin Sans', sans-serif;

  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;

}
.FriendInviteModal>label{

  font-weight: 400;
  position: absolute;
  top: 16px;
  font-size: 18px;
}

.FriendInviteModal>div{
  width: 95%;
  height: 80%;
  border-radius: 5px;
  position: absolute;
  bottom: 16px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.FriendInviteModal>div::-webkit-scrollbar{
display: none;
}
.FriendInviteModal>img{
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 8px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
}
.FriendInviteModal>img:hover{
  opacity: .8;
}

.FriendInviteModal>div>div{
  width: 100%;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; 
  margin-bottom: 10px;

}
.FriendInviteModal>div>div>div{
  width: 16px;
  height: 16px;
  border-radius: 20px;
  background-color: rgba(51, 51, 51, 0.603);
  position: absolute;
  left: 10px;


}

.FriendInviteModal>div>.online>div{
  background-color: rgb(0,148,255);
}

.FriendInviteModalContainer.shown>.FriendInviteModal>div>.online>button{

  opacity: 1;
  pointer-events: all;
}
.FriendInviteModal>div>div>label{
  position: absolute;
  left: 30px;
  -webkit-transform: translateY(2px);
      -ms-transform: translateY(2px);
          transform: translateY(2px);
 

}
.FriendInviteModal>div>div>button{
  background-color: rgb(0,148,255);
  font-family: 'Josefin Sans', sans-serif;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  color: white;
  cursor: pointer;
  border-style: solid;
  border-width: 0px;
  border-radius: 5px;
  width: 70px;
  height: 30px;
  position: absolute;
  right: 10px;
  opacity: .2;
  pointer-events: none;
  -webkit-transition: opacity .25s ease-in;
  -o-transition: opacity .25s ease-in;
  transition: opacity .25s ease-in;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.FriendInviteModal>div>div>button:hover{
  opacity: .8!important;
  
}
.FriendInviteModal>div>div>button.sent{
  pointer-events: none!important;

}
button>.sentlabel{
  position: absolute;
  opacity: 0;
}
button.sent>label{
  opacity: 0;
}
button.sent>.sentlabel{
  opacity: 1;
}

.ReplayContainer{
  -ms-user-select: none;
      user-select: none;
  font-family: 'Josefin Sans', sans-serif;
  color: white;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.ReplayLoading{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 250px;
  height: 70px;
  background-color: #222222;
  border-radius: 5px;
  font-weight: 400;
  position: relative;
}
.ReplayLoading>label{
  position: absolute;
  top: 16px;
}

.ReplayLoading>div{
  position: absolute;
  top: 48px;
}
.Notifications{
  -ms-user-select: none;
      user-select: none;
  font-family: 'Josefin Sans', sans-serif;
  color: white;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  position: absolute;
  font-weight: 400;
  width: 240px;
  height: 98%;
  position: absolute;
  left: 50%;
  top: 0px;
  -webkit-transform: translate(-50%);
      -ms-transform: translate(-50%);
          transform: translate(-50%);

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
    
  pointer-events: none;
  z-index: 20;




}
.Notification>label{
  pointer-events: none;
}
.Notifications>.Notification{
  width: 100%;
  background-color: rgb(13, 124, 255);
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 32px;
  font-size: 14px;
  opacity: 0;

  pointer-events: all;
  margin-bottom: 5px;
  border-radius: 5px;

  line-height: 16px;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  -webkit-transform: translateY(10px);
      -ms-transform: translateY(10px);
          transform: translateY(10px);
}
.Notifications>.Notification.shown{
  opacity: 1;
  -webkit-transform: translateY(0px);
      -ms-transform: translateY(0px);
          transform: translateY(0px);

}
main.transitioning>.Notifications{
  opacity: 1;


  
}
.ReplayContainer>div{
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
}

.ReplayContainer>.GameReplayContainer{
  opacity: 1;
}

.ReplayContainer.loading>.ReplayLoading{
  opacity: 1;



}

.ReplayContainer.loading>.GameReplayContainer{
  opacity: 0 !important;



}

.ReplayDock{

  -ms-user-select: none;

      user-select: none;
  font-family: 'Josefin Sans', sans-serif;
  color: white;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  position: absolute;
  font-weight: 400;


  background-color: rgba(0, 0, 0, 0.3);
  height: 130px;
  width: 328px;
  margin-top: 50px;
  position: relative;
  margin-bottom: 100px;
  border-radius: 10px;
  overflow: hidden;
}

.GameReplayContainer{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  top: 0px;

}

.GameReplayContainer>.GameDiv{
  position: relative;
  top: 0px;
  left: unset!important;
  -webkit-transform: translate(0px);
      -ms-transform: translate(0px);
          transform: translate(0px);
 
}

.CornerSelection{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin-top: 8px;
  position: relative;
  margin-left: 4px;
  margin-right: 4px;

}
.CornerSelection>div>div{
  width: 12px;
  height: 12px;
  border-radius: 20px;
  position: absolute;
  left: 4px;
}
.CornerSelection>div>label{
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  width: 50px;
  position: absolute;
  pointer-events: none;
  left: 18px;
  text-align: left;

}

.CornerSelection>div{

  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;

  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-left: 2px;
  margin-right: 2px;
  height: 20px;
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  outline: rgba(13, 126, 255, 0) 1px solid;

}
.CornerSelection>div:hover{
  background-color: rgba(0, 0, 0, 0.3);
}
.CornerSelection>div.selected{
  background-color: rgba(0, 0, 0, 0.3);
  outline: rgb(13, 124, 255) 1px solid;
}
.startendbtns{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin-top: 8px;
  margin-left: 2px;
  margin-right: 2px;
}

button{
  -ms-user-select: none;
      user-select: none;
  font-family: 'Josefin Sans', sans-serif;
  color: white;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;

  font-weight: 400;
}

.startendbtns>button{




  
  border-style: none;
  border-radius: 5px;
  height: 36px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-left: 4px;
  margin-right: 4px;
  cursor: pointer;
  background-color: rgb(13, 124, 255);
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  font-size: 16px;
}

.startendbtns>button:hover{
  background-color: rgb(0, 102, 218);
}

.EventCarousel{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;

  -webkit-box-align: center;

      -ms-flex-align: center;

          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 10px;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
 

}
.EventCarousel>div{
  background-color: rgba(0, 0, 0, 0.2);
  width: 90px;
  min-width: 100px;
  height: 36px;
  border-radius: 5px;
  margin-left: 4px;
 margin-right: 4px;
 display: -webkit-box;
 display: -ms-flexbox;
 display: flex;
 -webkit-box-orient: horizontal;
 -webkit-box-direction: normal;
     -ms-flex-direction: row;
         flex-direction: row;
 -webkit-box-align: center;
     -ms-flex-align: center;
         align-items: center;
 -webkit-box-pack: center;
     -ms-flex-pack: center;
         justify-content: center;
 cursor: pointer;
 -webkit-transition: all .25s ease-in;
 -o-transition: all .25s ease-in;
 transition: all .25s ease-in;
 position: relative;


 outline: rgba(13, 126, 255, 0) 1px solid;

}
.EventCarousel>div:hover,.EventCarousel>div.selected{
  background-color: rgba(0, 0, 0, 0.3);
}
.EventCarousel.transitionleft{
  -webkit-transform: translate(108px);
      -ms-transform: translate(108px);
          transform: translate(108px);
  -webkit-transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  transition: all .2s ease-in;
}
.EventCarousel.transitionright{

  -webkit-transform: translate(-108px);

      -ms-transform: translate(-108px);

          transform: translate(-108px);
  -webkit-transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  transition: all .2s ease-in;
}
.EventCarousel>.selectedItem{
  pointer-events: none;
  outline: rgb(13, 124, 255) 1px solid;
}

.EventCarousel>div>div{
  width: 12px;
  height: 12px;
  border-radius: 20px;
  position: absolute;
  left: 10px;
}
.EventCarousel>div>label{
  pointer-events: none;
  -webkit-transform: translateY(2px);
      -ms-transform: translateY(2px);
          transform: translateY(2px);
}
.isonline{
  background-color: #00A4FF !important;
}


.invitenotification{

  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;


}
.invitenotification>label{
  position: absolute;
  left: 5px;
  bottom: 0px;
  font-size: 14px;
  text-transform: capitalize;

}
.invitenotification>.plyname{
  top: 2px;
  
}
.invitenotification>div{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  position: absolute;
  right: 5px;  
}
.invitenotification>div>div{
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-orient: horizontal;
-webkit-box-direction: normal;
    -ms-flex-direction: row;
        flex-direction: row;
-webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;
-webkit-box-pack: center;
    -ms-flex-pack: center;
        justify-content: center;
width: 26px;
height: 26px;
background-color: rgba(0, 0 , 0, .18);
border-radius: 5px;
margin-left: 5px;
cursor: pointer;
-webkit-transition: all .25s ease-in;
-o-transition: all .25s ease-in;
transition: all .25s ease-in;
}
.invitenotification>div>div:hover{
  background-color: rgba(0, 0 , 0, .25);
}